import React from 'react';
import styled from 'styled-components';
import { glsp, media } from '@devseed-ui/theme-provider';

import {
  Fold,
  FoldInner,
  FoldHeader,
  FoldSuptitle,
  FoldLead,
  FoldBody,
  FoldBlock,
  FoldTitle,
  FoldBlockTitle
} from '../../styles/fold';
import { FoldMosaicFeatures } from '../../components/mosaic';

const FoldBlockTitleSmall = styled(FoldBlockTitle)`
  font-size: 2rem;
  line-height: calc(1em + 0.5rem);

  ${media.mediumUp`
    font-size: 2.25rem;
  `}

  ${media.largeUp`
    font-size: 2.5rem;
  `}
`;

const FoldBodyBenefits = styled(FoldBody)`
  grid-row-gap: ${glsp(4)};

  ${media.mediumUp`
    grid-row-gap: ${glsp(6)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(10)};
  `}
`;

const whyDevseedItems = [
  {
    title: 'Impactful Work',
    body: 'Engage in work that makes a real difference'
  },
  { title: 'Autonomy', body: 'Enjoy flexibility in how you work' },
  {
    title: 'Inspirational Team',
    body: 'Motivated, diverse, mission-driven colleagues and a culture of open collaboration and shared decision-making'
  }
];

const totalRewardsItems = [
  {
    title: 'Competitive Compensation',
    body: 'Fair and transparent pay aligned with our mission.'
  },
  {
    title: 'Health & Wellness Across Geographies',
    body: 'Comprehensive medical, dental, and vision plans with equitable benefits across all locations.'
  },
  {
    title: 'Paid Time Off & Wellness Leave',
    body: 'Substantial vacation policies, sick and parental leave for work-life balance.'
  },
  {
    title: 'Professional Growth',
    body: 'Robust budget for personal development and innovation labs participation, with active participation in forging your career path.'
  },
  {
    title: 'Remote Work Support',
    body: 'Flexible schedules, co-working space reimbursements, and a well-equipped remote worker setup.'
  },
  {
    title: 'Community Engagement',
    body: 'Opportunities to attend conferences and a strong focus on open source projects, enhancing skills and community impact.'
  },
  {
    title: 'In-Person Gatherings',
    body: 'All-Hands meeting and team-specific gatherings to foster collaboration and connection.'
  },
  {
    title: 'Financial Security',
    body: 'Geographic-specific pension contributions, 401(k) plan and profit-sharing bonuses.'
  }
];

const compPhilosophyItems = [
  {
    title: 'Building the Best Team',
    body: 'Attractive pay structure to recruit diverse top talent'
  },
  { title: 'Rewarding Fairly', body: 'Pay based on contribution and impact' },
  {
    title: 'Transparency & Feedback',
    body: 'Open dialogue about compensation and performance'
  }
];

export default function SectionBenefits() {
  return (
    <Fold>
      <FoldInner>
        <FoldHeader>
          <FoldSuptitle>Benefits</FoldSuptitle>
          <FoldTitle>Our benefits</FoldTitle>
          <FoldLead>
            <p>
              We want people to thrive at Development Seed. We believe you do
              your best work when you feel your best.
            </p>
          </FoldLead>
        </FoldHeader>
        <FoldBodyBenefits>
          <FoldBlock>
            <FoldBlockTitleSmall as='h3'>
              Why Choose Development Seed?
            </FoldBlockTitleSmall>
            <FoldMosaicFeatures items={whyDevseedItems} />
          </FoldBlock>
          <FoldBlock>
            <FoldBlockTitleSmall as='h3'>
              Total Rewards: A Comprehensive Approach
            </FoldBlockTitleSmall>
            <FoldMosaicFeatures items={totalRewardsItems} />
          </FoldBlock>
          <FoldBlock>
            <FoldBlockTitleSmall as='h3'>
              Our Compensation Philosophy
            </FoldBlockTitleSmall>
            <FoldMosaicFeatures items={compPhilosophyItems} />
          </FoldBlock>
        </FoldBodyBenefits>
      </FoldInner>
    </Fold>
  );
}
