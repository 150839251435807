import React from 'react';

import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionBody
} from '../../styles/section';
import { MosaicFeatures } from '../../components/mosaic';

const sectionLookingItems = [
  {
    title: 'Collaborate',
    body: 'Working as a team makes us stronger than any individual developer. You write clear Github tickets and communicate effectively on Slack and in-person.'
  },
  {
    title: 'Learn',
    body: 'Our technology approach is dynamic, focusing on the most effective solutions rather than just current trends. In some roles we require knowledge of specific languages, frameworks, or tools if they&apos;re the ones that best fit the need, otherwise we want to see you demonstrate you&apos;re able and excited to learn new ways to build.'
  },
  {
    title: 'Create for user impact',
    body: 'You create projects focused on both performance and maintainability. You write detailed documentation before, during, and after project implementation. You create projects with user needs in mind.'
  },
  {
    title: 'Care about the world and believe that we can do better',
    body: 'Social change is the foundation of everything we do. You are impatient about solving the world’s toughest challenges.'
  }
];

export default function SectionLooking() {
  return (
    <Section>
      <SectionHeader>
        <SectionHeadline>
          <SectionTitle>
            Our team comes from diverse cultures and has different interests,
            but there are a few qualities and values that we share
          </SectionTitle>
          <SectionSuptitle>Who we want to join the team</SectionSuptitle>
        </SectionHeadline>
      </SectionHeader>
      <SectionBody>
        <MosaicFeatures items={sectionLookingItems} />
      </SectionBody>
    </Section>
  );
}
