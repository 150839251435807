import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { themeVal, media, glsp } from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';
import { Button } from '@devseed-ui/button';

import { line } from '../../styles/motifs';

import Prose from '../../styles/typography/prose';
import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionBody
} from '../../styles/section';

const SectionPositions = styled(Section)`
  grid-row-gap: 0;
`;

const SectionPositionsHeader = styled(SectionHeader)`
  position: relative;
  padding-bottom: ${glsp()};

  ${media.mediumUp`
    padding-bottom: ${glsp(2)};
  `}

  ${media.largeUp`
    padding-bottom: ${glsp(3)};
  `}

  &::after {
    ${line};
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
  }
`;

const SectionPositionsBody = styled(SectionBody)`
  grid-column: content-start / content-end;
`;

const PositionsList = styled.ul``;

const PositionsListItem = styled.li`
  box-shadow: 0 0.25rem 0 0 ${themeVal('color.base')};
  padding: ${glsp(1, 0)};

  ${media.smallUp`
    padding: ${glsp(2, 0)};
  `}

  ${media.largeUp`
    padding: ${glsp(3, 0)};
  `}
`;

const Position = styled.article`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${glsp(themeVal('layout.gap.xsmall'))};

  ${media.smallUp`
    grid-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}
`;

const PositionHeader = styled.header`
  grid-row: 1;
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp(0.5)};
  grid-auto-rows: min-content;

  ${media.mediumUp`
    grid-column: 1 / span 4;
  `}

  ${media.largeUp`
    grid-column: 1 / span 4;
  `}
`;

const PositionTitle = styled(Heading)`
  margin: 0;
  font-size: 1.5rem;
  line-height: 2rem;

  ${media.mediumUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  ${media.largeUp`
    font-size: 2.5rem;
    line-height: 3rem;
  `}

  a,
  a:visited,
  a:not([class]),
  a:not([class]):visited {
    color: inherit;
  }
`;

const PositionSubtitle = styled.p`
  font-variation-settings: 'wdth' 96, 'wght' 512;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  opacity: 0.64;
`;

const PositionBody = styled(Prose)`
  font-variation-settings: 'wdth' 64, 'wght' 356;
  grid-column: 1 / span 4;

  ${media.mediumUp`
    grid-row: 1;
    grid-column: 5 / span 4;
  `}

  ${media.largeUp`
    grid-column: 6 / span 4;
  `}
`;

const PositionFooter = styled.footer`
  grid-column: 1 / span 4;

  ${media.mediumUp`
    grid-row: 2;
    grid-column: 5 / span 4;
  `}

  ${media.largeUp`
    grid-row: 1;
    grid-column: 11 / span 2;
    justify-self: end;
  `}
`;

const ApplyButton = styled(Button)`
  margin-left: ${glsp(-0.75)};

  ${media.largeUp`
    margin: 0;
  `}
`;

export default function SectionOpenPositions(props) {
  const { positions } = props;

  return (
    <SectionPositions>
      <SectionPositionsHeader>
        <SectionHeadline>
          <SectionTitle>Open positions</SectionTitle>
        </SectionHeadline>
      </SectionPositionsHeader>
      <SectionPositionsBody>
        <PositionsList>
          {positions.map((position) => (
            <PositionsListItem key={position.id}>
              <Position>
                <PositionHeader>
                  <PositionTitle>
                    <Link
                      title='View open position'
                      to={`/careers/${position.slug}`}
                    >
                      {position.frontmatter.title}
                    </Link>
                  </PositionTitle>
                  <PositionSubtitle>
                    {position.frontmatter.location}
                  </PositionSubtitle>
                </PositionHeader>
                <PositionBody>{position.frontmatter.teaser}</PositionBody>
                <PositionFooter>
                  <ApplyButton
                    forwardedAs='a'
                    variation='primary-text'
                    size='medium'
                    href={`/careers/${position.slug}`}
                    title='View open position'
                  >
                    Apply
                  </ApplyButton>
                </PositionFooter>
              </Position>
            </PositionsListItem>
          ))}
        </PositionsList>
      </SectionPositionsBody>
    </SectionPositions>
  );
}

SectionOpenPositions.propTypes = {
  positions: T.array
};
