import React from 'react';
import styled from 'styled-components';
import { media } from '@devseed-ui/theme-provider';

import UniversalGridder from '../../styles/universal-gridder';
import ContinuumImageGallery from '../../components/continuum-image-gallery';

import { stripe } from '../../styles/motifs';

import MediaSliderItem01 from '../../media/layout/careers/careers-slider-item-01.jpg';
import MediaSliderItem02 from '../../media/layout/careers/careers-slider-item-02.jpg';
import MediaSliderItem03 from '../../media/layout/careers/careers-slider-item-03.jpg';
import MediaSliderItem04 from '../../media/layout/careers/careers-slider-item-04.jpg';
import MediaSliderItem05 from '../../media/layout/careers/careers-slider-item-05.jpg';
import MediaSliderItem06 from '../../media/layout/careers/careers-slider-item-06.jpg';

const teamImages = [
  { url: MediaSliderItem01 },
  { url: MediaSliderItem02 },
  { url: MediaSliderItem03 },
  { url: MediaSliderItem04 },
  { url: MediaSliderItem05 },
  { url: MediaSliderItem06 }
];

export const TeamContinuumImageGallery = styled(UniversalGridder).attrs(
  (props) => ({
    as: 'div',
    grid: props.grid || {
      smallUp: ['full-start', 'full-end'],
      mediumUp: ['full-start', 'full-end'],
      largeUp: ['full-start', 'full-end']
    }
  })
)`
  grid-column: 1 / -1;

  &::before {
    ${stripe};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-row: 1;
    grid-column-start: content-2;
    grid-column-end: full-end;
    top: 100%;
    transform: translate(0, calc(-100% + 1rem));

    ${media.smallUp`
      transform: translate(0, calc(-100% + 2rem));
    `}

    ${media.mediumUp`
      transform: translate(0, calc(-100% + 3rem));
      grid-column-start: content-3;
    `}

    ${media.largeUp`
      transform: translate(0, calc(-100% + 4rem));
      grid-column-start: content-7;
    `}

    ${media.xlargeUp`
      transform: translate(0, calc(-100% + 6rem));
    `}
  }
`;

export default function TeamSlider() {
  return (
    <TeamContinuumImageGallery>
      <ContinuumImageGallery list={teamImages} alt='Team photo' />
    </TeamContinuumImageGallery>
  );
}
