import React from 'react';
import styled from 'styled-components';
import { themeVal, media, glsp } from '@devseed-ui/theme-provider';

import UniversalGridder from '../../styles/universal-gridder';
import { stripe } from '../../styles/motifs';

import Media from '../../components/media';

import MediaCollageItem01 from '../../media/layout/careers/careers-collage-item-01.jpg';
import MediaCollageItem02 from '../../media/layout/careers/careers-collage-item-02.jpg';
import MediaCollageItem05 from '../../media/layout/careers/careers-collage-item-03.png';
import MediaCollageItem04 from '../../media/layout/careers/careers-collage-item-04.png';
import MediaCollageItem03 from '../../media/layout/careers/careers-collage-item-05.gif';

export const MediaCollageSelf = styled(UniversalGridder).attrs((props) => ({
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
}))`
  grid-column: 1 / -1;

  &::before {
    ${stripe};
    z-index: 2;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    grid-row: 1;
    grid-column-start: full-start;
    grid-column-end: content-end;
    top: 50%;
    transform: translate(0, -25%);

    ${media.smallUp`
      grid-column-end: content-4;
    `}

    ${media.mediumUp`
      grid-column-end: content-8;
    `}

    ${media.largeUp`
      grid-column-end: content-end;
    `}
  }
`;

export const MediaCollageList = styled(UniversalGridder).attrs((props) => ({
  as: 'ul',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
}))`
  grid-row: 1;
  grid-row-gap: ${glsp(themeVal('layout.gap.xsmall'))};

  ${media.smallUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  figure {
    max-width: 100%;
    height: auto;

    img {
      min-width: auto;
    }
  }

  li:nth-child(1) {
    z-index: 1;
    grid-row: 1;
    grid-column: content-start / content-end;

    ${media.smallUp`
      grid-column: content-start / content-4;
    `}

    ${media.mediumUp`
      grid-column: content-start / content-6;
    `}

    ${media.largeUp`
      grid-column: content-start / content-8;
    `}
  }

  li:nth-child(2) {
    position: relative;
    z-index: 1;
    grid-row: 2;
    grid-column: content-2 / full-end;

    ${media.smallUp`
      grid-column: content-3 / full-end;
    `}

    ${media.mediumUp`
      z-index: 2;
      grid-row: 1;
      grid-column: content-6 / content-end;
      align-self: end;
    `}

    ${media.largeUp`
      grid-column: content-8 / content-12;
    `}
  }

  li:nth-child(3) {
    grid-row: 3;
    grid-column: content-start / content-4;

    ${media.smallUp`
      grid-row: 2;
      grid-column: content-start / content-3;
    `}

    ${media.mediumUp`
      grid-column: full-start / content-4;
    `}

    ${media.largeUp`
      grid-column: content-2 / content-6;
    `}
  }

  li:nth-child(4) {
    position: relative;
    z-index: 1;
    grid-row: 4;
    grid-column: full-start / content-3;

    ${media.smallUp`
      grid-row: 3;
    `}

    ${media.mediumUp`
      grid-row: 2;
      grid-column: content-4 / content-7;
    `}

    ${media.largeUp`
      grid-column: content-6 / content-9;
    `}
  }

  li:nth-child(5) {
    grid-row: 4;
    grid-column: content-3 / content-end;

    ${media.smallUp`
      grid-row: 3;
    `}

    ${media.mediumUp`
      grid-row: 2;
      grid-column: content-7 / content-end;
    `}

    ${media.largeUp`
      grid-column: content-9 / content-12;
      margin-right: ${glsp(-3)};
    `}
  }
`;

const collageImages = [
  MediaCollageItem01,
  MediaCollageItem02,
  MediaCollageItem03,
  MediaCollageItem04,
  MediaCollageItem05
];

export default function MediaCollage() {
  return (
    <MediaCollageSelf>
      <MediaCollageList>
        {collageImages.map((img) => (
          <li key={img}>
            <Media decoration='none' src={img} alt='Collage item' />
          </li>
        ))}
      </MediaCollageList>
    </MediaCollageSelf>
  );
}
