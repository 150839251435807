import React from 'react';
import styled from 'styled-components';
import { glsp, media } from '@devseed-ui/theme-provider';

import {
  Fold,
  FoldInner,
  FoldHeader,
  FoldBody,
  FoldBlock,
  FoldTitle
} from '../../styles/fold';

import awsLogoUrl from '../../media/layout/careers/partner-logos/aws_logo.png';
import esaLogoUrl from '../../media/layout/careers/partner-logos/esa_logo.png';
import ifrcLogoUrl from '../../media/layout/careers/partner-logos/ifrc_logo.png';
import msLogoUrl from '../../media/layout/careers/partner-logos/ms_logo.png';
import nasaLogoUrl from '../../media/layout/careers/partner-logos/nasa_logo.png';
import osmusLogoUrl from '../../media/layout/careers/partner-logos/osmus_logo.png';
import tncLogoUrl from '../../media/layout/careers/partner-logos/tnc_logo.png';
import wbLogoUrl from '../../media/layout/careers/partner-logos/wb_logo.png';

const LogoList = styled.ul`
  grid-column: content-start / content-end;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  justify-items: center;
  align-items: center;
  gap: ${glsp(5, 2)};
  ${media.mediumUp`
    grid-column: content-2 / content-end;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    gap: ${glsp(3, 5)};
  `}
  img {
    filter: grayscale(1);
    object-fit: contain;
  }
`;

export default function SectionPartners() {
  return (
    <Fold>
      <FoldInner>
        <FoldHeader>
          <FoldTitle>We partner with</FoldTitle>
        </FoldHeader>
        <FoldBody>
          <FoldBlock>
            <LogoList>
              <li>
                <a href='https://aws.amazon.com/'>
                  <img src={awsLogoUrl} width='60px' />
                </a>
              </li>
              <li>
                <a href='https://www.esa.int/'>
                  <img src={esaLogoUrl} width='90px' />
                </a>
              </li>
              <li>
                <a href='https://www.ifrc.org/'>
                  <img src={ifrcLogoUrl} width='115px' />
                </a>
              </li>
              <li>
                <a href='https://www.microsoft.com/'>
                  <img src={msLogoUrl} width='130px' />
                </a>
              </li>
              <li>
                <a href='https://www.nasa.gov/'>
                  <img src={nasaLogoUrl} width='68px' />
                </a>
              </li>
              <li>
                <a href='https://www.openstreetmap.us/'>
                  <img src={osmusLogoUrl} width='215px' />
                </a>
              </li>
              <li>
                <a href='https://www.nature.org/'>
                  <img src={tncLogoUrl} width='150px' />
                </a>
              </li>
              <li>
                <a href='https://www.worldbank.org/'>
                  <img src={wbLogoUrl} width='175px' />
                </a>
              </li>
            </LogoList>
          </FoldBlock>
        </FoldBody>
      </FoldInner>
    </Fold>
  );
}
