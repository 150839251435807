import React from 'react';
import T from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';
import Media from '../../components/media';

import MediaMidCover from '../../media/layout/careers/careers-mid-cover.jpg';
import SectionBenefits from './_section-benefits';
import SectionOpenPositions from './_section-positions';
import SectionLooking from './_section-looking';
import SectionValues from './_section-values';
import MediaCollage from './_section-media-collage';
import TeamSlider from './_section-team-slider';
import SectionPartners from './_section-partners';

const Careers = ({ data, location }) => {
  return (
    <Layout location={location} title='Careers'>
      <Inpage as='section'>
        <InpageHeader leadLayout='aside'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Join the team & make a better planet.</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                We&apos;re looking for mission-driven individuals eager to
                address some of the world&apos;s most challenging problems.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <MediaCollage />

          <SectionOpenPositions positions={data.careers.nodes} />

          <SectionBenefits />

          <SectionValues />

          <TeamSlider />

          <SectionLooking />

          <SectionPartners />

          <Media
            size='cover'
            decoration='top'
            src={MediaMidCover}
            alt='Team meeting'
          />
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Careers;

Careers.propTypes = {
  data: T.object,
  location: T.object
};

export const pageQuery = graphql`
  query {
    careers: allCareer(
      filter: { published: { eq: true } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        frontmatter {
          title
          teaser
          location
        }
        slug
      }
    }
  }
`;
