import React from 'react';

import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionBody
} from '../../styles/section';
import { MosaicFeatures } from '../../components/mosaic';

const sectionOfferItems = [
  {
    title: 'Healthy expectations',
    children: (
      <React.Fragment>
        <p>
          We&apos;re remote friendly, because wonderful teammates can come from
          anywhere. We have 3 offices, and trust that people will use the
          offices or work from elsewhere depending on what works best for them.
        </p>
        <p>
          It’s not a strict 9 to 5 workplace. Team members can work hours that
          fit their schedules allowing for collaboration with peers and clients
          across time zones. We&apos;re always improving how we communicate
          across time and distance, and do our best to respect the working hours
          of everyone in our wide-ranging set of time zones where team members
          live.
        </p>
        <p>
          We give clear guidance on minimizing notifications from services like
          slack and github, including turning off notifications during non-work
          hours.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'Good ideas come from all of us',
    children: (
      <React.Fragment>
        <p>
          Ideas that improve how we work and what we produce can come from
          anyone, and we trust each other to make decisions. With that
          decision-making power comes the responsibility of clearly documenting
          decisions before acting on them, as well as documenting the results of
          that work.
        </p>
        <p>
          We approach building the organization much like we approach building
          projects with our partners. We conduct user research to better
          understand the goals and challenges of team members.
        </p>
        <p>
          We scope out potential directions collaboratively, encourage input
          from the whole team, and build a vision for what we want to achieve
          together. We organize small teams to execute quickly on that vision.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'Growth & learning is part of the work',
    children: (
      <React.Fragment>
        <p>
          We actively examine how we work, what issues team members are facing,
          and how to best support and encourage the growth, confidence, and
          safety of everyone on the team. We use recurring 1:1s, mentorship, and
          learning plans to encourage personal and professional growth.
        </p>
        <p>
          Team members are encouraged to learn new skills, lead projects, and
          are given the space and autonomy to make decisions about their work.
          When possible, we form project teams in part based on the learning
          goals of individuals, allowing them the opportunity to learn while
          doing. We also have internal labs projects that can be used to try new
          things that advance the growth of individuals and the organization.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'Diversity & inclusion',
    children: (
      <React.Fragment>
        <p>
          We believe that diversity within our team is of utmost importance.
          With a diverse team comes a unique set of perspectives, talents,
          insights, and experiences. This makes our people, company, and tools
          stronger in how we can support our team, reach users and communities,
          and develop ideas. We believe the lack of diversity in the technology
          industry is a harmful problem that needs our attention.
        </p>
        <p>
          At Development Seed, we strive to make our diversity initiatives
          careful, long term, and intentional. We need to eliminate unconscious
          bias in hiring. We need to ensure that our workplace is supportive and
          inclusive.
        </p>
      </React.Fragment>
    )
  }
];

export default function SectionValues() {
  return (
    <Section>
      <SectionHeader>
        <SectionHeadline>
          <SectionTitle>
            We believe that solving complex challenges depends on diverse people
            coming together for a shared goal
          </SectionTitle>
          <SectionSuptitle>How we work</SectionSuptitle>
        </SectionHeadline>
      </SectionHeader>
      <SectionBody>
        <MosaicFeatures items={sectionOfferItems} />
      </SectionBody>
    </Section>
  );
}
